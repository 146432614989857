import * as React from "react";
import nextIcon from '../images/content/next-icon.svg';
import { Link } from "gatsby";
import { Fade } from "react-reveal";


const Forward = ({ to }) => {
    return (
        <Link to={to}>
            {to && (
                <div class="text-right pb-20 mr-20">
                    <Fade distance="30px" duration={1200} left>
                        <Link to={to}>
                            <img class="inline-block" src={nextIcon} />
                        </Link>
                    </Fade>
                </div>
            )}
            <div>
                <div class="h-6 absolute left-0 lg:left-1/2 right-0 bottom-0 bg-secondary"></div>
            </div>
        </Link>
    );
};

export default Forward;
import * as React from "react";
import Seo from "../components/seo"
import { Zoom, Slide, Fade } from 'react-reveal';
import bg2 from '../images/dashboard-background-2.png';
import Forward from '../components/forward';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import cross from '../images/cross.svg';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 0,
  marginBottom: 16,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  border: 0,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '&.Mui-expanded': {
    backgroundColor: '#000',
    color: '#fff',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));


const BuildPage = () => {
  const [expanded, setExpanded] = React.useState('panel-investment');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div class="relative">
      <Seo title="Build" />
      <div class="container mx-auto relative">
        <div class="grid grid-cols-12 mt-10 lg:mt-28 xl:mt-44">
          <div class="col-span-12 lg:col-span-5 flex flex-col">
            <h2 class="uppercase text-3xl font-sans font-medium tracking-wider lg:pl-10 mb-6 lg:mb-0">
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500}>Bu</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={100}>ild</Slide>
              </div>
            </h2>
            <div class="hidden lg:block mt-auto pr-20 pt-20">
              <Fade duration={1200}>
                <img src={bg2} />
              </Fade>
            </div>
          </div>
          <div class="col-span-12 lg:col-span-7 font-sans text-3xl relative">
            <div style={{ width: 83 }} class="invisible lg:visible absolute -left-28 -top-12">
              <Fade delay={200} left distance="18px"><img src={cross} /></Fade>
            </div>
            <h1 class="hidden lg:block uppercase font-sans text-lg lg:text-2lg xl:text-xl tracking-wider mb-20">
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500}>HG VENTURES IS A</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={50}>NEW MODEL OF VENTURE</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={100}> CAPITAL: VENTURE X</Slide>
              </div>
            </h1>

            <h1 class="lg:hidden uppercase font-sans text-lg lg:text-xl tracking-wider mb-10">
              <Fade>
                HG VENTURES IS A NEW MODEL OF VENTURE CAPITAL: VENTURE X
              </Fade>
            </h1>

            <div class="font-sans text-gray text-base lg:text-lg font-light tracking-wide mb-10">
              <p class="mb-10">
                <Fade delay={100}>
                  We identify markets where we look to make a meaningful and positive difference.
                </Fade>
              </p>
              <p class="mb-10">
                <Fade delay={100}>
                  We handpick and build exceptional teams to launch market leading companies.
                </Fade>
              </p>
              <p class="mb-10">
                <Fade delay={100}>
                  We invest our own funds.
                </Fade>
              </p>
              <p class="mb-10">
                <Fade delay={100}>
                  We execute with operating ingredients built from proven success.
                </Fade>
              </p>
              <p class="mb-10">
                <Fade delay={100}>
                  We offer a distinctly different way to go your own way. Where vision leverages proven execution and creates a singular opportunity to shape more than a company: a legacy.
                </Fade>
              </p>

            </div>
          </div>
        </div>

        <Forward to="/companies/" />
      </div>
    </div>
  )
};

export default BuildPage
